import {useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import classNames from 'classnames'
import {ExpandArrow} from '../../../../../../../icons'
import sc from '../../../classes.scss'
import sd from '../date/date.scss'
import s from './additional-info-toggle.scss'

export const AdditionalInfoToggle = ({opened, active, listLayout}) => {
  const {t} = useTranslation()

  const classes = classNames(s.root, sc.textLineHeight, sd.listHeaderTitle, sd.color, {
    [s.listContentColor]: active && listLayout,
  })

  return (
    <div className={classes} role="button" aria-expanded={opened}>
      {t('additionalInfoToggle')}
      <span className={classNames(s.icon, {[s.reversedIcon]: opened})}>
        <ExpandArrow size={14} />
      </span>
    </div>
  )
}
