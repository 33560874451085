import classNames from 'classnames'
import React from 'react'
import {isDateTbd} from '@wix/wix-events-commons-statics'
import {useEventDateInformation} from '../../../../../../../commons/hooks/dates'
import s from './date.scss'
import {DateProps} from '.'

export const Date = ({event}: DateProps) => {
  const {shortStartDate} = useEventDateInformation(event.id)
  const dateTbd = isDateTbd(event)

  return (
    <div className={classNames(s.listHeaderTitle, s.color, s.date)} data-hook={dateTbd ? 'ev-date-tbd' : 'ev-date'}>
      {shortStartDate}
    </div>
  )
}
