import classNames from 'classnames'
import React from 'react'
import sc from '../../../classes.scss'
import sd from '../date/date.scss'
import {LinkToPage} from '../../../link-to-page'
import s from './title.scss'
import {TitleProps} from '.'

export const Title = ({event}: TitleProps) => {
  const classes = classNames(s.root, sc.textLineHeight, sd.listHeaderTitle, sd.color)

  return (
    <span className={classes} data-hook="ev-list-item-title">
      <LinkToPage event={event}>{event.title}</LinkToPage>
    </span>
  )
}
