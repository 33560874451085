import classNames from 'classnames'
import React from 'react'
import {MainListTitle} from '../../main-list-title'
import {LoadMoreButton} from '../../load-more-button'
import {getEvents} from '../../../selectors/events'
import {
  getCardBorderWidth,
  getComponentConfig,
  getListDividerWidth,
  getListStripWidth,
  isListLayout,
} from '../../../selectors/settings'
import {useWidgetState} from '../../../hooks/state-provider'
import {ListItemContainer} from '../../event-container/list-item-container'
import {CardContainer} from '../../event-container/card-container'
import {isMainTitleVisible} from '../../../selectors/list-settings'
import {CardsMobileItem} from './cards-mobile-item'
import s from './cards-mobile.scss'

export const CardsMobile = () => {
  const events = useWidgetState(getEvents)
  const componentConfig = useWidgetState(getComponentConfig)
  const isDesktopListLayout = isListLayout(componentConfig)
  const dividerWidth = isDesktopListLayout ? getListDividerWidth(componentConfig) : getCardBorderWidth(componentConfig)
  const stripWidth = getListStripWidth(componentConfig, true)
  const mainTitleVisible = useWidgetState(isMainTitleVisible)

  const className = classNames(s.container, isDesktopListLayout ? s.listContainer : s.cardContainer, {
    [s.noTitle]: !mainTitleVisible,
  })

  const Container = isDesktopListLayout ? ListItemContainer : CardContainer

  return (
    <div className={className} data-hook="ev-list-layout">
      <MainListTitle />
      <ul>
        {events.map((event: ExtendedEvent, index: number) => (
          <Container
            key={event.id}
            event={event}
            lastItem={events.length - 1 === index}
            mobile
            dividerWidth={dividerWidth}
            stripWidth={stripWidth}
          >
            {hovered => <CardsMobileItem event={event} hovered={hovered} />}
          </Container>
        ))}
      </ul>
      <LoadMoreButton />
    </div>
  )
}
