import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {SocialBar} from '../../../social-bar'
import s from './share.scss'
import {ShareProps} from '.'

export const Share = ({event}: ShareProps) => {
  const {t} = useTranslation()
  return (
    <div className={s.container}>
      <SocialBar t={t} spacing={24} event={event} row />
    </div>
  )
}
