import React from 'react'
import {ExperimentNames, LIST_LAYOUT} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import {Calendar} from '../calendar'
import {Cards} from '../cards'
import {EmptyState} from '../empty-state'
import {ListLayout} from '../list-layout'
import {SideBySide} from '../side-by-side'
import {SingleEvent} from '../single-event'
import {CardsMobile} from '../mobile-layouts/cards-mobile/cards-mobile'
import {LayoutProps} from '.'

export const Layout = ({listLayout, singleEventWidget, emptyStateVisible, mobile}: LayoutProps) => {
  const newMobileWidgetLayouts = useExperiments().experiments.enabled(ExperimentNames.NewMobileWidgetLayouts)

  if (emptyStateVisible) {
    return <EmptyState />
  }

  if (singleEventWidget) {
    return <SingleEvent />
  }

  if (mobile) {
    if (newMobileWidgetLayouts) {
      switch (listLayout) {
        case LIST_LAYOUT.CALENDAR:
          return <Calendar />
        default:
          return <CardsMobile />
      }
    } else {
      switch (listLayout) {
        case LIST_LAYOUT.CALENDAR:
          return <Calendar />
        default:
          return <ListLayout />
      }
    }
  }

  switch (listLayout) {
    case LIST_LAYOUT.GRID:
    case LIST_LAYOUT.NEW_GRID:
      return <Cards />
    case LIST_LAYOUT.SIDE_BY_SIDE:
      return <SideBySide />
    case LIST_LAYOUT.CALENDAR:
      return <Calendar />
    default:
      return <ListLayout />
  }
}
