import React from 'react'
import classNames from 'classnames'
import {useExperiments} from '@wix/yoshi-flow-editor'
import {isRegistrationClosed, isTicketed, isWithoutRegistration, ExperimentNames} from '@wix/wix-events-commons-statics'
import {LinkToPage} from '../link-to-page'
import settingsParams from '../../../settingsParams'
import {useSettings} from '../../../hooks/settings'
import s from './rsvp-button.scss'
import {RsvpButtonProps} from '.'

const getButtonClasses = (style: number, fullWidth: boolean, mobile: boolean, link = false) => {
  const classes = [s.button, fullWidth ? s.fullWidth : '', link ? s.link : '']

  if ([2, 4].includes(style)) {
    const radiusClass = style === 4 ? s.evRsvpButtonRoundedBorderRadius : s.evRsvpButtonBorderRadius
    classes.push(s.evRsvpHollowButtonFont, s.evRsvpHollowButtonColor, s.evRsvpButtonBorder, radiusClass)
  }

  if ([1, 3].includes(style)) {
    const radiusClass = style === 3 ? s.evRsvpButtonRoundedBorderRadius : s.evRsvpButtonBorderRadius
    classes.push(s.evRsvpButtonFont, s.evRsvpButtonColor, s.evRsvpButtonBackground, radiusClass)
  }

  return classNames(classes, {[s.mobile]: mobile})
}

export const RsvpButton = ({
  event,
  style,
  dataHook = 'ev-rsvp-button',
  navigateToPage,
  fullWidth,
  minWidth = 160,
  mobile,
  maxWidth,
  additionalStyle = {},
  hasUrl,
  settings,
  eventRestricted,
  ensureLoginAndNavigateToPage,
}: RsvpButtonProps) => {
  const {getWithFallback} = useSettings(settings)
  const fixNavigateToPageEnabled = useExperiments().experiments.enabled(ExperimentNames.FixNavigateToPage)
  const eventClosed = isRegistrationClosed(event)
  const withoutRegistration = isWithoutRegistration(event)

  let text: string
  if (withoutRegistration) {
    text = getWithFallback(settingsParams.multiNoRegButtonText, settingsParams.registrationClosedButtonText)
  } else if (isTicketed(event)) {
    if (eventClosed) {
      text = getWithFallback(settingsParams.multiTicketedClosedBtnText, settingsParams.registrationClosedButtonText)
    } else {
      text = getWithFallback(settingsParams.multiTicketedButtonText, settingsParams.listButtonText)
    }
  } else {
    if (eventClosed) {
      text = getWithFallback(settingsParams.multiRsvpClosedBtnText, settingsParams.registrationClosedButtonText)
    } else {
      text = getWithFallback(settingsParams.multiRsvpButtonText, settingsParams.listButtonText)
    }
  }

  const onClick = (e: React.MouseEvent) => {
    if (!fixNavigateToPageEnabled) {
      e.stopPropagation()
      navigateToPage()
    } else {
      if (eventRestricted) {
        navigateToPage()
      } else {
        e.stopPropagation()
        ensureLoginAndNavigateToPage()
      }
    }
  }

  const styles: React.CSSProperties = {
    minWidth,
    maxWidth,
    ...additionalStyle,
  }
  const className = getButtonClasses(style, fullWidth, mobile, hasUrl)

  return hasUrl ? (
    <LinkToPage event={event} dataHook={dataHook} style={styles} className={className} allowExternal>
      {text}
    </LinkToPage>
  ) : (
    <button data-hook={dataHook} style={styles} className={className} type="button" onClick={onClick}>
      {text}
    </button>
  )
}
