import {useState, KeyboardEvent} from 'react'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import {isItemOpened} from '../selectors/layout'
import {isAdditionalComponentsHidden} from '../selectors/list-settings'
import {useWidgetActions, useWidgetState} from './state-provider'

export const useAdditionalItemsToggle = ({event}: {event: ExtendedEvent}) => {
  const fixNavigateToPageEnabled = useExperiments().experiments.enabled(ExperimentNames.FixNavigateToPage)
  const additionalComponentsHidden = useWidgetState(state => isAdditionalComponentsHidden(state, event.id))
  const {openListLayoutItem, closeListLayoutItems} = useWidgetActions()

  const [hovered, setHovered] = useState(false)
  const opened = useWidgetState(state => isItemOpened(state, event.id))

  const onKeyPress = (e: KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter') {
      toggle(e)
    }
  }

  const onMouseEnter = () => {
    setHovered(true)
  }

  const onMouseLeave = () => {
    setHovered(false)
  }

  const toggle = e => {
    if (fixNavigateToPageEnabled && e.target.getAttribute('data-hook') === 'ev-rsvp-button') {
      return
    }
    if (opened) {
      closeListLayoutItems()
    } else if (!additionalComponentsHidden) {
      openListLayoutItem(event.id)
    }
    e.stopPropagation()
  }

  return {
    hovered,
    opened,
    events: {
      onKeyPress,
      onMouseEnter,
      onMouseLeave,
      onClick: toggle,
    },
  }
}
