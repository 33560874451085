import classNames from 'classnames'
import React, {CSSProperties} from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useWidgetState} from '../../../hooks/state-provider'
import {getComponentConfig, isListLayout} from '../../../selectors/settings'
import {useAdditionalItemsToggle} from '../../../hooks/use-additional-items-toggle'
import {ListItemContainerProps} from './interfaces'
import s from './list-item-container.scss'

export const ListItemContainer = ({
  event,
  mobile,
  dividerWidth,
  stripWidth,
  lastItem,
  children,
}: ListItemContainerProps) => {
  const listLayout = useWidgetState(state => isListLayout(getComponentConfig(state)))
  const {opened, hovered, events} = useAdditionalItemsToggle({event})

  const getContainerStyle = (): CSSProperties => {
    const padding = `${stripWidth + (mobile ? 0 : 10)}px 0`

    if (hovered || opened) {
      return {
        padding,
        marginTop: -dividerWidth,
        borderBottomWidth: dividerWidth,
        borderTopWidth: dividerWidth,
      }
    }

    return {
      padding,
      borderBottomWidth: dividerWidth,
      borderBottomColor: lastItem ? 'transparent' : undefined,
    }
  }

  return (
    <li
      style={getContainerStyle()}
      {...events}
      data-hook={DH.listItem}
      className={classNames(s.container, mobile && !listLayout ? s.cardBorderColor : s.listDividerColor, {
        [s.listHoveredBorderColor]: listLayout && (hovered || opened),
      })}
    >
      {children(hovered)}
    </li>
  )
}
